import {Fill, Icon, Circle, Stroke, Style, Text} from 'ol/style';

var params = require('./parameters');

const _default = function(feature, resolution) {
    return new Style({
        fill: new Fill({
            color: '#f3bfab'
        }),
        stroke: new Stroke({
            color: '#880000',
            width: 1
        }),
    });
};
export { _default as default }

const _tinh = function(feature, resolution) {
  var text = feature.get('title') || '';
  var colorId = feature.get('color_id') || 0;
  if (colorId > 0 && text != '') {
    return new Style({
      fill: new Fill({
        color: params.palette[colorId-1]
      }),
      stroke: new Stroke({
        color: '#880000',
        width: 0.2
      }),
      text: new Text({
        font: '13px Times New Roman, sans-serif',
        fill: new Fill({ color: '#000' }),
        stroke: new Stroke({
          color: '#fff', width: 2
        }),
        text: text.toLocaleUpperCase()
      })
    })
  }
  return _default(feature, resolution);
}
export { _tinh as tinh }

const _huyen = function(feature, resolution) {
  var text = feature.get('title') || '';
  var colorId = feature.get('color_id') || 0;
  if (colorId > 0) {
    return new Style({
      fill: resolution < 500 ? new Fill({
        color: params.palette[colorId-1]
      }): null,
      stroke: new Stroke({
        color: '#880000',
        width: 0.2
      }),
      text: new Text({
        font: '13px Times New Roman,sans-serif',
        fill: new Fill({ color: '#000' }),
        stroke: new Stroke({
          color: '#fff', width: 2
        }),
        text: text.toLocaleUpperCase()
      })
    })
  }
  return _default(feature, resolution);
}
export { _huyen as huyen }

const _xa = function(feature, resolution) {
  var text = feature.get('title') || '';
  var colorId = feature.get('color_id') || 0;
  if (colorId > 0) {
    return new Style({
      fill: resolution < 40 ? new Fill({
        color: params.palette[colorId-1]
      }): null,
      stroke: new Stroke({
        color: '#880000',
        width: 0.2
      }),
      text: new Text({
        font: 'bold 13px Times New Roman,sans-serif',
        fill: new Fill({ color: '#000' }),
        stroke: new Stroke({
          color: '#fff', width: 2
        }),
        text: text
      })
    })
  }
  return _default(feature, resolution);
}
export { _xa as xa }

const _dao = function(feature, resolution) {
  var text = feature.get('title') || '';
  var colorId = feature.get('color_id') || 0;
  if (colorId > 0) {
    return new Style({
      fill: resolution < 40 ? new Fill({
        color: params.palette[colorId-1]
      }): null,
      stroke: new Stroke({
        color: '#880000',
        width: 0.2
      }),
      text: new Text({
        font: '13px Times New Roman,sans-serif',
        fill: new Fill({ color: '#000' }),
        stroke: new Stroke({
          color: '#fff', width: 2
        }),
        text: text.toLocaleUpperCase()
      })
    })
  }
  return _default(feature, resolution);
}
export { _dao as dao }

const _thuyhe = function(feature, resolution) {
  var type = feature.get('type') || 0;
  var text = feature.get('title') || '';
  if (type > 0) {
    return new Style({
      stroke: new Stroke({
        color: '#6bbfff',
        width: type === 1 ? 2.0 : type === 2 ? 1.0 : type == 3 ? 0.5 : 0.5
      }),
      text: new Text({
        font: 'italic 14px Times New Roman, sans-serif',
        placement: 'line',
        fill: new Fill({ color: '#6bbfff' }),
        stroke: new Stroke({
          color: '#fff', width: 2
        }),
        text: text
      })
    })
  }
  return _default(feature, resolution);
}
export { _thuyhe as thuyhe }

const _giaothong = function(feature, resolution) {
  var type = feature.get('type') || 0;
  var text = feature.get('name') || '';
  if (type > 0) {
    return [
      new Style({
        stroke: new Stroke({
          color: '#f99d48',
          width: type === 1 ? 4.0 : type === 2 ? 3.0 : type == 3 ? 2.0 : 1.0
        }),
        image: new Circle({
          radius: 16,
          stroke: new Stroke({
            color: 'white',
            width: 2
          }),
          fill: new Fill({
            color: 'green'
          })
        })
      }),
      new Style({
        stroke: new Stroke({
          color: '#ffd285',
          width: type === 1 ? 3.0 : type === 2 ? 2.0 : type == 3 ? 1.0 : 0.5
        }),
        text: new Text({
          font: '13px Times New Roman,sans-serif',
          placement: 'line',
          fill: new Fill({ color: '#000' }),
          stroke: new Stroke({
            color: '#fff', width: 2
          }),
          text: text
        }),
      }),
    ]
  }
  return _default(feature, resolution);
}
export { _giaothong as giaothong }

const _biengioi = function(feature, resolution) {
  return new Style({
    stroke: new Stroke({
      color: '#ebb0cc',
      width: 5
    }),
  });
}
export { _biengioi as biengioi }

const _marker = function(feature, resolution) {
  var text = feature.get('title') || '';
  return new Style({
    image: new Circle({
      radius: 3,
      stroke: new Stroke({
        color: 'white',
        width: 2
      }),
      fill: new Fill({
        color: 'red'
      })
    }),
    text: new Text({
      font: '14px Times New Roman,sans-serif',
      fill: new Fill({ color: '#000' }),
      stroke: new Stroke({
        color: '#fff', width: 2
      }),
      textBaseline: 'bottom',
      text: text
    }),
  });
}
export { _marker as marker }

const _highlight = function(feature, resolution) {
  return new Style({
    stroke: new Stroke({
      color: '#ff0000',
      width: 2
    }),
  });
}
export { _highlight as highlight }