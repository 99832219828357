import MVT from 'ol/format/MVT';
import TileLayer from 'ol/layer/Tile';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile'
import OSM from 'ol/source/OSM';
import { createXYZ } from 'ol/tilegrid';
import { createTileUrlFunction } from 'ol-tilecache'

var params = require('./parameters');
var styles = require('./styles');

const _osm = new TileLayer({
  source: new OSM()
});
export { _osm as osm }

const _tinh = new VectorTileLayer({
  declutter: true,
  source: new VectorTileSource({
    tilePixelRatio: 1,
    tileGrid: createXYZ({maxZoom: 21}),
    format: new MVT(),
    tileUrlFunction: createTileUrlFunction(params.host + '/geoserver/gwc/service/tms/1.0.0/vn:tinh@EPSG:900913@pbf/{z}/{x}/{-y}.pbf'),
    //url: params.host + '/geoserver/gwc/service/tms/1.0.0/vn:tinh@EPSG:900913@pbf/{z}/{x}/{-y}.pbf',
  }),
  style: styles.tinh
});
export { _tinh as tinh }

const _huyen = new VectorTileLayer({
  declutter: true,
  source: new VectorTileSource({
    tilePixelRatio: 1,
    tileGrid: createXYZ({maxZoom: 21}),
    format: new MVT(),
    tileUrlFunction: createTileUrlFunction(params.host + '/geoserver/gwc/service/tms/1.0.0/vn:huyen@EPSG:900913@pbf/{z}/{x}/{-y}.pbf'),
    //url: params.host + '/geoserver/gwc/service/tms/1.0.0/vn:huyen@EPSG:900913@pbf/{z}/{x}/{-y}.pbf',
  }),
  style: styles.huyen
});
export { _huyen as huyen }

const _xa = new VectorTileLayer({
  declutter: true,
  source: new VectorTileSource({
    tilePixelRatio: 1,
    tileGrid: createXYZ({maxZoom: 21}),
    format: new MVT(),
    tileUrlFunction: createTileUrlFunction(params.host + '/geoserver/gwc/service/tms/1.0.0/vn:xa@EPSG:900913@pbf/{z}/{x}/{-y}.pbf'),
    //url: params.host + '/geoserver/gwc/service/tms/1.0.0/vn:xa@EPSG:900913@pbf/{z}/{x}/{-y}.pbf',
  }),
  style: styles.xa
});
export { _xa as xa }

const _dao = new VectorTileLayer({
  declutter: true,
  source: new VectorTileSource({
    tilePixelRatio: 1,
    tileGrid: createXYZ({maxZoom: 21}),
    format: new MVT(),
    tileUrlFunction: createTileUrlFunction(params.host + '/geoserver/gwc/service/tms/1.0.0/vn:dao@EPSG:900913@pbf/{z}/{x}/{-y}.pbf'),
    // url: params.host + '/geoserver/gwc/service/tms/1.0.0/vn:dao@EPSG:900913@pbf/{z}/{x}/{-y}.pbf',
  }),
  style: styles.dao
});
export { _dao as dao }

const _thuyhe = new VectorTileLayer({
  declutter: true,
  source: new VectorTileSource({
    tilePixelRatio: 1,
    tileGrid: createXYZ({maxZoom: 21}),
    format: new MVT(),
    tileUrlFunction: createTileUrlFunction(params.host + '/geoserver/gwc/service/tms/1.0.0/vn:thuyhe@EPSG:900913@pbf/{z}/{x}/{-y}.pbf'),
    // url: params.host + '/geoserver/gwc/service/tms/1.0.0/vn:thuyhe@EPSG:900913@pbf/{z}/{x}/{-y}.pbf',
  }),
  style: styles.thuyhe
});
export { _thuyhe as thuyhe }

const _giaothong = new VectorTileLayer({
  declutter: true,
  source: new VectorTileSource({
    tilePixelRatio: 1,
    tileGrid: createXYZ({maxZoom: 21}),
    format: new MVT(),
    tileUrlFunction: createTileUrlFunction(params.host + '/geoserver/gwc/service/tms/1.0.0/vn:giaothong@EPSG:900913@pbf/{z}/{x}/{-y}.pbf'),
    // url: params.host + '/geoserver/gwc/service/tms/1.0.0/vn:giaothong@EPSG:900913@pbf/{z}/{x}/{-y}.pbf',
  }),
  style: styles.giaothong
});
export { _giaothong as giaothong }

const _biengioi = new VectorTileLayer({
  declutter: true,
  source: new VectorTileSource({
    tilePixelRatio: 1,
    tileGrid: createXYZ({maxZoom: 21}),
    format: new MVT(),
    tileUrlFunction: createTileUrlFunction(params.host + '/geoserver/gwc/service/tms/1.0.0/vn:biengioi@EPSG:900913@pbf/{z}/{x}/{-y}.pbf'),
    // url: params.host + '/geoserver/gwc/service/tms/1.0.0/vn:biengioi@EPSG:900913@pbf/{z}/{x}/{-y}.pbf',
  }),
  style: styles.biengioi
});
export { _biengioi as biengioi }

const _marker = new VectorTileLayer({
  declutter: true,
  source: new VectorTileSource({
    tilePixelRatio: 1,
    tileGrid: createXYZ({maxZoom: 21}),
    format: new MVT(),
    tileUrlFunction: createTileUrlFunction(params.host + '/geoserver/gwc/service/tms/1.0.0/vn:marker@EPSG:900913@pbf/{z}/{x}/{-y}.pbf'),
  }),
  style: styles.marker
});
export { _marker as marker }