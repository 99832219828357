import 'ol/ol.css';
import {Map, View} from 'ol';
import {fromLonLat} from 'ol/proj';
import VectorTileLayer from 'ol/layer/VectorTile';

var layers = require('./lib/layers');
var styles = require('./lib/styles');

const map = new Map({
  layers: [
    // layers.osm,
    layers.tinh,
    layers.huyen,
    layers.xa,
    layers.dao,
    layers.thuyhe,
    layers.giaothong,
    // layers.marker,
    layers.biengioi,
  ],
  target: 'map',
  view: new View({
    center: fromLonLat([105.0, 16.0]),
    zoom: 6,
  }),
});

function showInfo(feature) {
  const properties = feature.getProperties();
  info.innerText = JSON.stringify(properties, null, 2);
  info.style.opacity = 1;
}

let selectionTinh = {};
let selectionHuyen = {};
let selectionXa = {};
let selectElement = { 'value': 'singleselect-hover' };
const selectionLayerTinh = new VectorTileLayer({
  map: map,
  renderMode: 'vector',
  source: layers.tinh.getSource(),
  style: function (feature) {
    if (feature.getId() in selectionTinh) {
      showInfo(feature);
      return styles.highlight();
    }
  },
});

const selectionLayerHuyen = new VectorTileLayer({
  map: map,
  renderMode: 'vector',
  source: layers.huyen.getSource(),
  style: function (feature) {
    if (feature.getId() in selectionHuyen) {
      showInfo(feature);
      return styles.highlight();
    }
  },
});

const selectionLayerXa = new VectorTileLayer({
  map: map,
  renderMode: 'vector',
  source: layers.xa.getSource(),
  style: function (feature) {
    if (feature.getId() in selectionXa) {
      showInfo(feature);
      return styles.highlight();
    }
  },
});

map.on(['click', 'pointermove'], function (event) {
  layers.tinh.getFeatures(event.pixel).then(function (features) {
    if (!features.length) {
      selectionTinh = {};
      selectionLayerTinh.changed();
      return;
    }

    const feature = features[0];

    if (!feature) {
      return;
    }
    const fid = feature.getId();

    if (selectElement.value.indexOf('singleselect') === 0) {
      selectionTinh = {};
    }

    selectionTinh[fid] = feature;

    selectionLayerTinh.changed();
  });
  layers.huyen.getFeatures(event.pixel).then(function (features) {
    if (!features.length) {
      selectionHuyen = {};
      selectionLayerHuyen.changed();
      return;
    }

    const feature = features[0];

    if (!feature) {
      return;
    }
    const fid = feature.getId();

    if (selectElement.value.indexOf('singleselect') === 0) {
      selectionHuyen = {};
    }

    selectionHuyen[fid] = feature;

    selectionLayerHuyen.changed();
  });
  layers.xa.getFeatures(event.pixel).then(function (features) {
    if (!features.length) {
      selectionXa = {};
      selectionLayerXa.changed();
      return;
    }

    const feature = features[0];

    if (!feature) {
      return;
    }
    const fid = feature.getId();

    if (selectElement.value.indexOf('singleselect') === 0) {
      selectionXa = {};
    }

    selectionXa[fid] = feature;

    selectionLayerXa.changed();
  });
});