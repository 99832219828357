const _host = 'https://bigeos.xyz:8443';
// const _host = '';
export { _host as host }

const _palette = [
    'rgba(243,191,171, 0.2)',
    'rgba(255,249,182, 0.2)',
    'rgba(190,217,162, 0.2)',
    'rgba(235,176,204, 0.2)',
    'rgba(254,230,206, 0.2)',
    'rgba(160,151,197, 0.2)'
];
export { _palette as palette }
